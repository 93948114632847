import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Kesari = () => {
  const headingbanner = {
    title: `Kesari`,
    content: `An ERP Platform`,
  };

  const data = {
    images: ["kesari-web.webp", "kesari-2.webp"],
    firstAlt: "UI UX dashboard to check the booking status",
    firstTitle: "Bookings Overview",
    secondAlt: "UI screen to check various travel packages",
    secondTitle: "All Packages Screen",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["Digital Transformation", "UI UX Designing", "Angular Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The existing business process of the company lacked a comprehensive and cohesive platform that can offer a unified ecosystem to address the needs of various departments in an efficient way.`,
          `The team of Kesari found it difficult to manage and analyse the data from various departmental processes that hamper the productivity and the overall performance of the business. `,
          `Earlier they used multiple platforms to manage their resources and run the business operations but it proved to be an inefficient way of management. `,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our solutions provided the team with an extensive platform to manage the processes of various departments effectively and efficiently.`,
          `We offered end-to-end solutions and created an integrated dashboard to plan the resources. With this upgraded platform they can also customise the data according to the business requirements and decide on the working process of different travel departments.`,
        ],
      },
    ],
    image: ["kesari-problem.webp"],
  };

  const projectData = {
    title: `Dashboard`,
    para: [
      `We created this interface to offer an overview of the business processes and assist them to make informed decisions.`,
    ],
    content: {
      image: ["kesari-projectone.webp"],
      text: `The lead funnel chart assists the Sales department to strategise their pitch and the booking overview table assists the finance and ops department to run the business smoothly.`,
    },
    content2: {
      image: ["kesari-projectone-2.webp"],
      text: `This interface provides detailed information on the quotation request along with the tour package and family details. We also provided an option to sort and filter the data for enhanced productivity. `,
    },
  };

  const projectData2 = {
    title: `Bookings`,
    para: [
      `The booking interface gives complete information about the specific customer along with their quotation, travel arrangements, itineraries, etc.`,
      `This assists the team to look into the customer detail in one place reducing their time to shift into different screens. `,
    ],
    content: {
      image: ["kesari-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Packages`,
    para: [
      `This screen gives holistic information on the travel arrangements, check-in-check-out date, activities included, etc. It also provided information on other packages available.`,
    ],
    content: {
      image: [
        "kesari-projectthree.webp",
        "kesari-projectthree-2.webp",
        "kesari-projectthree-3.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `Being a travel assist platform, it was important for them to have a holistic platform to offer services to customers while increasing productivity and focusing on achieving business goals. `,
        `However, they didn’t have an extensive platform and used multiple systems to assist customers with travel information.`,
        `With our comprehensive solution, we offered an end-to-end platform that can be used by all the departments of the travel agency to manage the data efficiently and effectively. `
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={26} />
      </div>
    </Layout>
  );
};

export default Kesari;

export const Head = () => (
  <Seo title="Kesari- An ERP Platform Case Study | Octet Design Studio" description="We assisted Kesari by digitally transforming their travel assist platform and offering our services on UI UX Designing & Angular Development." />
)